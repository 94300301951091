import React, { Component, useEffect } from 'react';
import Navbar from "../components/Navbar/Navbar";
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import Selector from "../components/LanguageSelector/Selector.js";
import '../i18n/i18n';
import colours from "../style/colours";

const ResumePage = () => {
    const [isDesktop, setIsDesktop] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState('en');

    const { t, i18n } = useTranslation();

    const gradientStyle = `linear-gradient(135deg, ${colours['primary']} 0%, ${colours['secondary']} 100%)`

    React.useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
      }, [selectedLanguage]);

    return (

        <div >
            <Navbar />
            <Selector setSelectedLanguage={setSelectedLanguage} />
            <Fade duration={1000} distance="30px">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <h1 
                        style={{ 
                            fontSize: 40,
                            color: 'black',
                            borderRadius: 4,
                            backgroundImage: `linear-gradient(60deg, ${colours['primary']} 0%, ${colours['secondary']} 100%)`, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent" 
                        }}>
                            {t('button.resume')}
                    </h1>
                    <Link
                        to="http://vivek.kandathil.ca/files/Resume_VivekKandathil.pdf"
                        activeStyle={{ color: "red" }}
                        target="blank"
                    >
                        <img src={require("../images/pdf-png.png")} alt={"PDF"} style={{ width: 25, marginTop: 8, marginLeft: 40 }} />
                    </Link>
                    
                </div>
                {isDesktop ? <h2 style={{ fontSize: 20, color: 'black', fontWeight: 'bold', marginTop: 20 }}>E-Mail: vivekandath@gmail.com · {t('resume.phone')}: +1 (613) 301-0684 </h2> : <h2></h2>}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>

                    <div style={{ alignItems: 'center', justifyContent: 'center', margin: 40, textAlign: 'left' }}>
                        {isDesktop ? <h3>{t('resume.h')}</h3> : <h2></h2>}
                        <hr />
                        <h2 style={{ fontWeight: 'bold', marginBottom: 30, marginTop: 30, fontSize: 40 }}>{t('resume.education')}</h2>
                        <Link
                            to="https://www.mcgill.ca"
                        >
                            <img src={require(selectedLanguage === 'fr' ? "../images/mcgill-fr.png" : "../images/mcgill.png")} alt={"McGill University"} style={{ marginBottom: 30, width: isDesktop ? 200 : 160 }} />
                        </Link>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 20 }}>BSc. Software Engineering · {t('resume.dates.september')} 2019 - {t('resume.dates.april')} 2024</h3>
                        <hr />
                        <h2 style={{ fontWeight: 'bold', marginBottom: 30, marginTop: 30, fontSize: 40 }}>{t('resume.employment')}</h2>
                        <Link
                            to="https://www.nuance.com/index.html"
                        >
                            <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> Microsoft</h3>
                        </Link>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>{t('roles.softwareDeveloperIntern')} · {t('resume.dates.may')} - {t('resume.dates.august')} 2022, {t('resume.dates.may')} - {t('resume.dates.august')} 2023</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.nuance.p1')}</li> 
                            <li>{t('resume.nuance.p2')}</li>
                            <li>{t('resume.nuance.p3')}</li>
                            <li>{t('resume.nuance.p4')}</li>
                        </ul>
                        <Link
                            to="https://www.hivestack.com/"
                        >
                            <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> Hivestack</h3>
                        </Link>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>{t('roles.softwareDeveloperIntern')} · {t('resume.dates.january')} 2022 - {t('resume.dates.april')} 2022</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.hivestack.p1')}</li> 
                            <li>{t('resume.hivestack.p2')}</li>
                            <li>{t('resume.hivestack.p3')}</li>
                        </ul>
                        <Link
                            to="https://www.resmed.com/en-us/"
                        >
                            <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> ResMed</h3>
                        </Link>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>{t('roles.associateSoftwareEngineer')} · {t('resume.dates.may')} 2021 - {t('resume.dates.august')} 2021</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.resmed.p1')}</li> 
                            <li>{t('resume.resmed.p2')}</li>
                            <li>{t('resume.resmed.p3')}</li>
                            <li>{t('resume.resmed.p4')}</li>
                        </ul>
                        <Link
                            to="https://www.nokia.com"
                        >
                            <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> Nokia</h3>
                        </Link>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>WaveSuite - {t('roles.softwareDeveloperIntern')} · {t('resume.dates.september')} 2020 - {t('resume.dates.april')} 2021</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.nokia1.p1')}</li> 
                            <li>{t('resume.nokia1.p2')}</li>
                            <li>{t('resume.nokia1.p3')}</li>
                            <li>{t('resume.nokia1.p4')}</li>
                        </ul>
                        <br />
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>{t('roles.intern')} · {t('resume.dates.july')} - {t('resume.dates.august')} 2018</h3>
                        <h3>{t('resume.nokia2.p1')}</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.nokia2.p2')}</li>
                            <li>{t('resume.nokia2.p3')}</li>
                        </ul>
                        <Link
                            to="https://www.staples.ca"
                        >
                            <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> Staples</h3>
                        </Link>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>{t('roles.stocker')} · {t('resume.dates.august')} - {t('resume.dates.september')} 2019</h3>
                        <h3 style={{ fontSize: isDesktop ? 18 : 15 }}>{t('resume.staples.p1')}</h3>
                        <hr />
                        <h2 style={{ fontWeight: 'bold', marginBottom: 30, marginTop: 30, fontSize: 40 }}>{t('resume.skills')}</h2>
                        <h3> <b style={{ fontSize: 18 }}>{t('resume.skillsList.languages')}:    </b> Java, Python, JavaScript, Bash, HTML/CSS</h3>
                        <h3> <b style={{ fontSize: 18 }}>{t('resume.skillsList.libraries')}:    </b> ReactJS / React Native, Vue.js, Node.js, Angular.js, GatsbyJS, JavaFX, Spring, Swagger/OpenAPI</h3>
                        <h3> <b style={{ fontSize: 18 }}>{t('resume.skillsList.tools')}:    </b> AWS (S3, DynamoDB, CloudFront, Lambda, SQS/SES), Docker, Ansible, Git / Bitbucket, JIRA, Maven/Gradle, Terraform, SQLAlchemy, Alembic, Figma</h3>
                        <hr />
                        <h2 style={{ fontWeight: 'bold', marginBottom: 30, marginTop: 30, fontSize: 40 }}>{t('button.projects')}</h2>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}><a style={{ color: colours['secondary'] }} href="https://www.mcgill.ca/study/2022-2023/courses/comp-361d1">Splendor (Online Multiplayer Game)</a> · {t('resume.dates.september')} - {t('resume.dates.april')} 2023</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.splendor.p1')}</li>
                            <li>{t('resume.splendor.p2')}</li>
                            <li>{t('resume.splendor.p3')}</li>
                            <li>{t('resume.splendor.p4')}</li>
                        </ul>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}><a style={{ color: colours['secondary'] }} href="https://github.com/SolidGuard/solidguard">SolidGuard</a> · {t('resume.dates.january')} - {t('resume.dates.august')} 2022</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.solidguard.p1')}</li> 
                            <li>{t('resume.solidguard.p2')}</li>
                        </ul>
                        <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> Hackathons</h3>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}><a style={{ color: colours['secondary'] }} href="https://devpost.com/software/fast-food-haven">Fast Food Haven ({t('resume.fastfoodhaven.winner')})</a> · {t('resume.dates.january')} 2020</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.fastfoodhaven.p1')}</li> 
                            <li>{t('resume.fastfoodhaven.p2')}</li>
                        </ul>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}><a style={{ color: colours['secondary'] }} href="https://devpost.com/software/123scheduler">123 Scheduler</a> · {t('resume.dates.november')} 2022</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.loadboard.p1')}</li> 
                            <li>{t('resume.loadboard.p2')}</li>
                            <li>{t('resume.loadboard.p3')}</li>
                        </ul>
                        <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> {t('resume.personal')}</h3>
                        <Link
                            to="/"
                            className="cta-btn cta-btn--hero"
                            style={{ margin: "10px 10px 10px 0" }}
                        >
                            {t('button.viewHome')}
                        </Link>
                        <Link
                            to="https://github.com/vivekandathil"
                            className="cta-btn cta-btn--hero"
                        >
                            {t('button.github')}
                        </Link>
                        <hr />
                        <h2 style={{ fontWeight: 'bold', marginBottom: 30, marginTop: 30, fontSize: 40 }}>{t('resume.volunteering')}</h2>
                        <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> {t('resume.mchacks.n')}</h3>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>{t('resume.mchacks.t')} · {t('resume.dates.january')} 2023</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.mchacks.p1')}</li>
                            <li>{t('resume.mchacks.p2')}</li>
                        </ul>
                        <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> {t('resume.ottawa.n')}</h3>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>{t('resume.ottawa.t')} · {t('resume.dates.september')} 2018</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.ottawa.p1')}</li>
                            <li>{t('resume.ottawa.p2')}</li>
                        </ul>
                        <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> McGill Tech Fair</h3>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>New Residence Hall · {t('resume.dates.september')} 2019 - Current</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.techfair')}</li>
                        </ul>
                        <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> {t('resume.ferrari.t')}</h3>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>Ottawa · {t('resume.dates.june')} 2016-2018</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.ferrari.p1')}</li>
                        </ul>
                        <h3 style={{ fontSize: 29, backgroundImage: gradientStyle, "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent", marginTop: 20 }}> {t('resume.philippines.t')}</h3>
                        <h3 style={{ fontSize: 18, fontWeight: 'bold' }}> {t('resume.philippines.h')}, Ottawa · {t('resume.dates.june')} - {t('resume.dates.july')} 2017</h3>
                        <ul style={{ fontSize: isDesktop ? 18 : 15, width: '80%' }}>
                            <li>{t('resume.philippines.p1')}</li>
                            <li>{t('resume.philippines.p2')}</li>
                        </ul>
                    </div>

                </div>
            </Fade >
        </div >

    )
}

export default ResumePage